import Vue from 'vue'
import App from '@view/App.vue'
import text from '@region/text.json'
import config from '@region/config.js'
import axios from 'axios';
import { addListener, launch } from 'devtools-detector';

Vue.prototype.$text = text;
Vue.prototype.$config = config;
Vue.config.productionTip = false;

// addListener((isOpen) => {
//     if(isOpen && document.body.clientWidth > 1024){
//         // let target = "";
//         // try {
//         //     window.open("about:blank", (target = "_self"));
//         // } catch (err) {
//         //     let a = document.createElement("button");
//         //     a.onclick = function() {
//         //         window.open("about:blank", (target = "_self"));
//         //     };
//         //     a.click();
//         // }
//     }
// })
// launch();


Vue.prototype.getCode = function(){
    let axiosParam = {
        url: '/soccer/leaflet/agentInviteCode',
        method: 'get',
        headers: { 'X-Requested-With': 'XMLHttpRequest','DevicePlatform': '1'},
        //响应之前
        transformResponse: [function(data) {
            return data;
        }]
    }

    return new Promise(function(resolve, reject) {

        axios(axiosParam).then(result => {
            result = JSON.parse(result.data);
            if(result.code === 200){
                let inviteCode;
                if(result.data){
                    inviteCode = '?code=' + result.data.inviteCode;
                }
                resolve(inviteCode)
            }else{
                alert('request error!');
            }
        }).catch(reject);

    })
}

new Vue({
  render: h => h(App),
}).$mount('#app')
