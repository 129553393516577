<template>
    <div id="app">
        <header class="header">
            <section class="header-inner flex-between" :class="{'fixed': isFixed}">
                <div class="header-item">
                    <img class="icon-logo" :src="require('@iamge/logo_'+$config.region+'.png')"/>
                </div>
                <div class="anchor-point-box flex-middle">
                    <a :class="['anchor-point-item', {'sel' : headerSel == index}]" v-for="(item, index) in $text.header" :key="index" :href="'#anchor_point_'+index">{{item}}</a>
                </div>
                <div class="header-item"></div>
            </section>
        </header>

        <section class="main">
            <tl-scroll :overflow-x="false" @scroll="scroll" @updated="getOffset">
                <a id="anchor_point_0" ref="ap_0"></a>
                <div class="block-0">
                    <t-img :src="require('@iamge/banner.jpg')" :width="1920" :height="1080"/>
                    <div class="block-0-skin">
                        <img class="icon-logo" :src="require('@iamge/logo_'+$config.region+'.png')"/>
                        <p class="banner-text-0">{{$text.bannerText[0]}}</p>
                        <h1 class="banner-text-1">{{$text.bannerText[1]}}</h1>
                        <p class="banner-text-2">{{$text.bannerText[2]}}</p>
                        <p class="banner-text-2">{{$text.bannerText[3]}}</p>

                        <div class="statistics flex-center">
                            <div class="statistics-item">
                                <div class="icon-box flex-middle">
                                    <i class="statistics-icon-0"></i>
                                </div>
                                <h3>204,437</h3>
                                <h6 class="text-0" v-html="$text.statistics[0]"></h6>
                            </div>
                            <div class="statistics-item">
                                <div class="icon-box flex-middle">
                                    <i class="statistics-icon-1"></i>
                                </div>
                                <h3>5</h3>
                                <h6 class="text-0" v-html="$text.statistics[1]"></h6>
                            </div>
                            <div class="statistics-item">
                                <div class="icon-box flex-middle">
                                    <i class="statistics-icon-2"></i>
                                </div>
                                <h3>50,376</h3>
                                <h6 class="text-0" v-html="$text.statistics[2]"></h6>
                            </div>
                            <div class="statistics-item">
                                <div class="icon-box flex-middle">
                                    <i class="statistics-icon-3"></i>
                                </div>
                                <h3>703,579,170,412</h3>
                                <h6 class="text-0" v-html="$text.statistics[3]"></h6>
                            </div>
                        </div>

                        <div class="btns flex-center">
							<template v-if="$config.region == 'vn'">
								<div class="tg-btn" style="padding-right:0.2rem" @click="linkTo($config.registerUrl, true)">
								    <samp></samp>
								    <samp></samp>
								    <samp></samp>
								    <samp></samp>
								    {{$text.btns[0]}}
								</div>
							</template>
							<template v-else>
								<span class="register-btn" @click="linkTo($config.registerUrl, true)">{{$text.btns[0]}}</span>
								<div class="tg-btn" v-if="$config.tgUrl" @click="linkTo($config.tgUrl)">
								    <samp></samp>
								    <samp></samp>
								    <samp></samp>
								    <samp></samp>
								    {{$text.btns[1]}}
								    <i class="tg-icon"></i>
								</div>
							</template>
                        </div>
                    </div>
                </div>
                <a id="anchor_point_1" ref="ap_1"></a>
                <div class="block-1 flex-between">
                    <div class="text-box">
                        <h2 class="text-1">{{$text.block1[1]}}</h2>
                        <p class="text-0">{{$text.block1[0]}}</p>
                        <p class="text-2">{{$text.block1[2]}}</p>
                        <p class="text-3">{{$text.block1[3]}}</p>
                        <p class="text-4">{{$text.block1[4]}}</p>
                        <p class="text-5">{{$text.block1[5]}}</p>
                        <p class="text-6">{{$text.block1[6]}}</p>
                        <p class="text-7">{{$text.block1[7]}}</p>
                    </div>
                    <t-img :src="require('@iamge/block-2.png')" :width="781" :height="927"/>
                </div>

                <a id="anchor_point_2" ref="ap_2"></a>
                <article class="two-there-bg">
                    <div class="block-2 flex">
                        <img class="item-1" :src="require('@iamge/block2-0.jpg')">
                        <div class="right-box">
                            <div class="flex-between">
                                <img class="item-2" :src="require('@iamge/block2-1.jpg')">
                                <div class="item-3 flex-middle">{{$text.block2[0]}}</div>
                                <img class="item-4" :src="require('@iamge/block2-2.jpg')">
                            </div>
                            <div class="flex-between">
                                <div class="item-5 flex-middle">
                                    <div>
                                        <p class="title">{{$text.block2[1]}}</p>
                                        <p>{{$text.block2[2]}}</p>
                                    </div>
                                </div>
                                <img class="item-6" :src="require('@iamge/block2-3.jpg')">
                                <div class="item-7 flex-middle">{{$text.block2[3]}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="block-3">
                        <p class="text-0">{{$text.block3[0]}}</p>
                        <h2 class="text-1">{{$text.block3[1]}}</h2>
                        <p class="text-2">{{$text.block3[2]}}</p>
                        <p class="text-3">{{$text.block3[3]}}</p>
                        <t-img class="step-box" :src="require('@region/img/step.png')" :width="473" :height="234"/>
                    </div>
                </article>

                <a id="anchor_point_3" ref="ap_3"></a>
                <div class="block-4">
                    <p class="text-0">{{$text.block4[0]}}</p>
                    <p class="text-1">{{$text.block4[1]}}</p>
                    <p class="text-2">{{$text.block4[2]}}</p>
                    <div class="item-skin flex-center">
                        <div class="item-1">
                            <i class="ball"></i>
                            <h6 class="text-3">{{$text.block4[3]}}</h6>
                            <h1 class="text-4">2%</h1>
                            <p class="text-5" v-html="$text.block4[4]"></p>
                        </div>
                        <div class="item-2">
                            <i class="ball"></i>
                            <h6 class="text-3">{{$text.block4[3]}}</h6>
                            <h1 class="text-4">8%</h1>
                            <p class="text-5" v-html="$text.block4[5]"></p>
                        </div>
                        <div class="item-3">
                            <i class="ball"></i>
                            <h6 class="text-3">{{$text.block4[3]}}</h6>
                            <h1 class="text-4">15%</h1>
                            <p class="text-5" v-html="$text.block4[6]"></p>
                        </div>
                        <div class="item-4">
                            <i class="ball"></i>
                            <h6 class="text-3">{{$text.block4[3]}}</h6>
                            <h1 class="text-4">40%</h1>
                            <p class="text-5" v-html="$text.block4[7]"></p>
                        </div>
                    </div>
                </div>

                <a id="anchor_point_4" ref="ap_4"></a>
                <t-img class="block-5" :src="require('@region/img/block5.jpg')" :width="1920" :height="1461"/>
                <t-img class="block-7" :src="require('@region/img/block7.jpg')" :width="1920" :height="1288"/>

                <a id="anchor_point_5" ref="ap_5"></a>
                <div class="block-8">
                    <t-img class="block8-bg" :src="require('@region/img/block8.png')" :width="1920" :height="882"/>
                    <div class="block8-inner">
                        <p class="text-0">{{$text.block8[0]}}</p>
                        <p class="text-1">{{$text.block8[1]}}</p>
                        <p class="text-2">{{$text.block8[2]}}</p>
                        <div class="flex-center"><span class="agent-btn" @click="linkTo($config.registerUrl, true)">{{$text.block8[3]}}</span></div>
                    </div>
                </div>

                <a id="anchor_point_6" ref="ap_6"></a>
                <div class="block-9 flex-between">
                    <t-img class="block9-left" :src="require('@iamge/block9-left.jpg')" :width="549" :height="844"/>
                    <div class="block-box">
                        <p class="text-0">Q&A</p>
                        <div class="types flex-center">
                            <span v-for="(item, index) in $text.qa" :key="index" :class="['type-item', {'sel': typeSel == index}]" @click="typeSel = index;qIndex=-1">{{item.name}}</span>
                        </div>
                        <div class="info-box">
                            <tl-scroll :overflow-x="false" style="height:4.48rem">
                                <div v-for="(item, index) in $text.qa[typeSel].list" :key="index" :class="['info-skin', {'expand': qIndex==index}]" @click="qIndex= qIndex==index ? -1 : index">
                                    <div class="info-q">{{item.q}}</div>
                                    <div class="info-a">
                                        <span :class="{'link': item.link}" @click="linkTo($config[item.link])">{{item.a}}</span>
                                    </div>
                                </div>
                            </tl-scroll>
                        </div>
                    </div>
                    <t-img class="block9-right" :src="require('@iamge/block9-right.jpg')" :width="557" :height="844"/>
                </div>
                <div class="block-10">
                    <div class="title flex-middle">
                        <span class="gap"></span>
                        <span class="txt" @click="linkTo($config.registerUrl, true)">{{$text.block10[0]}}</span>
                        <span class="gap"></span>
                    </div>
                    <div class="block10-inner flex-between-middle">
                        <img class="block10-item1" :src="require('@iamge/footer-icon-1.png')"/>
                        <div >
                            <div class="block10-item2">
                                <p class="type-name">{{$text.block10[1]}}</p>
                                <img :src="require('@iamge/footer-icon-2.png')"/>
                            </div>
                            <div class="block10-item3">
                                <p class="type-name">{{$text.block10[2]}}</p>
                                <img :src="require('@iamge/footer-icon-3.png')"/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="block-11 flex-middle-center">
                    <p class="block-11-inner">{{$text.block11}}</p>
                </div>
            </tl-scroll>
        </section>
    </div>   
</template>

<script>
import less1 from '@assets/less/main_pc.less';
import less2 from '@region/region.less';

import TlScroll from "tl-scroll";
import TImg from "@/components/TImg.vue";
export default {
    name: "App",
    components: {
        [TlScroll.name]: TlScroll,
        [TImg.name]: TImg
    },
    data(){
        return {
            isFixed: false,
            headerSel: 0,
            typeSel: 0,
            qIndex: 0,

            offsets: [],
            bodyHeight: 0,
            code: null
        }
    },
    created(){
        this.setHmtlFontSize();

        const code = location.search;
        if( !/code/.test(code) ){
            this.getCode().then( reCode => {
                this.code = reCode;
            });
        }else{
            this.code = code;
        }
    },
    methods: {
        scroll(val, dom, type){
            this.isFixed = Boolean(val);

            let arr = [].concat(this.offsets || []);
            arr.reverse();
            let height = this.bodyHeight / 3;
            let index = arr.length-1;
            let targetVal = val + height;

            for(let i=0; i<arr.length; i++){
                if(targetVal >= arr[i]){
                    break;
                }
                index --;
            }
            this.headerSel = index;
        },
        linkTo(url, flg){
            if(url){
                if(flg){
                    url += '/#/register' + (this.code || '');
                }
                window.open(url)
            }
        },
        setHmtlFontSize(){
            const html = document.documentElement;
            const body = document.body;
            const fun = () => {
                let width = Math.max(1366, body.clientWidth);
                html.style.fontSize = Math.floor( width / 1920 * 100) + 'px';
                this.bodyHeight = body.clientHeight;
            }
            window.addEventListener("resize", fun);
            fun();
        },
        getOffset(){
            let {ap_0, ap_1, ap_2, ap_3, ap_4, ap_5, ap_6} = this.$refs;
            this.offsets = [ap_0.offsetTop, ap_1.offsetTop, ap_2.offsetTop, ap_3.offsetTop, ap_4.offsetTop, ap_5.offsetTop, ap_6.offsetTop];
        }
    }
};
</script>