<template>
    <div class="img-box" ref="box" :style="{height: boxHeight, backgroundColor: boxHeight ? '#efefef' : 'transparent'}">
        <img :src="src" ref="img">
    </div>
</template>

<script>
export default {
    name: "TImg",
    data(){
        return {
            boxHeight: ''
        }
    },
    props: ['src', 'width', 'height'],
    mounted(){
        const {box, img} = this.$refs;
        const {width, height} = this;

        const boxWidth = box.clientWidth;
        this.boxHeight = height * (boxWidth / width) + 'px';

        img.onload = ()=>{
            this.boxHeight = '';
        }
    }
};
</script>